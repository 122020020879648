var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [_c("b-col", [_c("h1", [_vm._v("Portals")])])],
        1
      ),
      false
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "label",
                    { staticClass: "mr-4", attrs: { for: "text-search" } },
                    [_vm._v("Search")]
                  ),
                  _c("b-form-input", {
                    attrs: { id: "text-search" },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.setSearch($event)
                      }
                    },
                    model: {
                      value: _vm.filter.q,
                      callback: function($$v) {
                        _vm.$set(_vm.filter, "q", $$v)
                      },
                      expression: "filter.q"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticStyle: { "padding-top": "1.75em" },
                  attrs: { cols: "3" }
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-2",
                      staticStyle: { width: "45%" },
                      attrs: { variant: "primary" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setSearch($event)
                        }
                      }
                    },
                    [_vm._v("Search")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticStyle: { width: "45%" },
                      attrs: { variant: "light" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.resetSearch($event)
                        }
                      }
                    },
                    [_vm._v("Reset")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            [
              _c("rest-table", {
                attrs: {
                  endpoint: "portal/list",
                  filter: _vm.activeFilter,
                  fields: _vm.fields
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(actions)",
                    fn: function(data) {
                      return [
                        _c("span", [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.testEmail(data.item.providerId)
                                }
                              }
                            },
                            [_vm._v("Test e-mail")]
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }