<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col>
        <h1>Portals</h1>
      </b-col>

    </b-row>

    <b-row v-if="false">
      <b-col cols="4">
        <label for="text-search" class="mr-4">Search</label>
        <b-form-input id="text-search" class="" v-model="filter.q" @keydown.enter="setSearch"></b-form-input>
      </b-col>

      <b-col cols="3" style="padding-top: 1.75em;">
        <b-button variant="primary" class="mr-2" style="width: 45%;" v-on:click.prevent="setSearch">Search</b-button>
        <b-button variant="light" style="width: 45%;" v-on:click.prevent="resetSearch">Reset</b-button>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <rest-table
            endpoint="portal/list"
            :filter="activeFilter"
            :fields="fields">

          <template v-slot:cell(actions)="data">
            <span>
              <!-- <a href="#" @click.prevent="viewPortal(data.item.portalHostname)">View</a> | -->
              <a href="#" @click.prevent="testEmail(data.item.providerId)">Test e-mail</a>
            </span>
          </template>
        </rest-table>
      </b-col>
    </b-row>  
  </b-container>
</template>

<script>
  import RestTable from '@/components/RestTable.vue'

  export default {
    name: 'Portals',
    components: {
      RestTable,
    },
    data () {
      return {
        filter: {
          q: '',
        },
        activeFilter: {
          q: '',
        },
        statuses: [],
        fields: [
          {
            key: 'providerName',
            label: 'Name',
            sortable: false,
          },
          {
            key: 'portalHostname',
            label: 'Hostname',
            sortable: false
          },
          {
            key: 'actions',
            label: 'Actions',
            //class: 'link-icons',
          },
        ],
      }
    },
    methods: {
      setSearch () {
        this.activeFilter.q = this.filter.q 
      },
      resetSearch() {
        this.filter.q = ''
        
        this.setSearch()
      },
      /*
      viewPortal(hostname) {
        console.log(hostname)
        localStorage.setItem('forceHostname', hostname)
        localStorage.setItem('adminToken', localStorage.getItem('jwt'))
        localStorage.removeItem('jwt')
        location.href = '/'
      },*/
      testEmail (providerId) {
        this.$http.post(
          'providers/' + providerId + '/test-portal-email',
          {
            emailAddress: this.$root.$data.me.EMail
          }
        ).then(response => {
          if (response.data.success) {
            this.$notify({
              type: 'success',
              text: 'Test e-mail sent'
            })
          } else {
            alert('Error while sending message: ' + response.data.errorMessage)
          }
        }) 
      }
    },
    mounted () {

    }
  }
</script>

<style scoped>

</style>